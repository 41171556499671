import LazyLoad from "vanilla-lazyload";
import {tns} from "tiny-slider";
import CookiesEuBanner from 'cookies-eu-banner';

const loadingScreen = document.getElementById('loading-screen');
window.addEventListener('load', e => {
    //loadingscreen
    loadingScreen.classList.add('hide');
})

//cookie banner
new CookiesEuBanner(function () {
    // Your code to launch when user accept cookies
  }, true);

//lazy load
var lazyLoadInstance = new LazyLoad({});

//tiny slider
const sliderElement = document.getElementById('slider');

if(sliderElement != null) {
    var slider = tns({
        container: sliderElement,
        mode: 'gallery',
        items: 1,
        slideBy: 'page',
        speed: 700,
        lazyload: true,
        controls: false,
        nav: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayButtonOutput: false,
    })
}